import { useEffect } from 'react'
import { gsap, Power3 } from 'gsap'

export const useTransitionIn = (ref, { isReady, selectors }) => {
  const { footer } = selectors

  useEffect(() => {
    const $footer = ref.current.querySelector(footer)

    if (isReady) {
      gsap.fromTo(
        $footer,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          delay: 0.1,
          ease: Power3.easeOut,
        }
      )
    } else {
      gsap.set($footer, { opacity: 0 })
    }
  }, [ref, isReady, footer])
}
