import React from 'react'
import PropTypes from 'prop-types'
import { Img } from '@/components/Img'
// import { VideoLoop } from '@/components/VideoLoop'
import { VideoLoopFull } from '@/components/VideoLoopFull'
// import { VideoPlayer } from '@/components/VideoPlayer'

const Media = ({ mediaType, isFull, video, ...props }) => {
  if (mediaType === 'image') {
    return <Img {...props} isFull={isFull} />
  }

  if (mediaType === 'video_loop') {
    return <VideoLoopFull {...video} />
  }
  // if (mediaType === 'video_loop') {
  //   return isFull ? <VideoLoopFull {...video} /> : <VideoLoop {...video} />
  // }

  // if (mediaType === 'vimeo_loop') {
  //   return isFull ? <VimeoLoopFull {...video} /> : <VimeoLoop {...video} />
  // }

  // if (mediaType === 'video_player') {
  //   return <VideoPlayer {...props} />
  // }

  console.warn(`Media: format not supported: ${mediaType}`)

  return null
}

Media.propTypes = {
  mediaType: PropTypes.oneOf([
    'image',
    'video_loop',
    'video_player',
    'vimeo_loop',
  ]),
  props: PropTypes.object,
}

export { Media }
