import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import * as styles from './VideoLoopFull.styles.scss'

const LoadableVideoEmbed = loadable(() => import('@/components/VideoEmbed'), {
  resolveComponent: (components) => components.VideoEmbed,
})

const VideoLoopFull = ({ isPlaying, isLooping, src }) => {
  const ref = useRef(null)

  return (
    <div className={styles.VideoLoopFull} ref={ref}>
      <LoadableVideoEmbed
        src={src}
        isPlaying={isPlaying}
        width="100%"
        height="100%"
        hasControls={false}
        isMuted
        playsinline
        loop={isLooping}
      />
    </div>
  )
}

VideoLoopFull.defaultProps = {
  isPlaying: true,
  isLooping: true,
}

VideoLoopFull.propTypes = {
  isPlaying: PropTypes.bool,
  isLooping: PropTypes.bool,
  src: PropTypes.string,
}

export { VideoLoopFull }
