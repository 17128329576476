import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'

import { useBloks } from '@/storyblok/hooks/useBloks'
import { BlockRouter } from '@/storyblok/components/BlockRouter'
import Page from '@/templates/Page'

import { FullScreenGallery } from '@/blocks/FullScreenGallery'

const PageStoryblok = ({ story }) => {
  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Page>
        <BlockRouter
          blocks={useBloks(story.content.body || [])}
          types={{
            full_screen_gallery: FullScreenGallery,
          }}
        />
      </Page>
    </SbEditable>
  )
}

PageStoryblok.propTypes = {
  story: PropTypes.object,
}

export default PageStoryblok
