import { useEffect, useState, useRef, useCallback } from 'react'
import { wrap } from '@/utils/wrap'
import { gsap } from 'gsap'

/**
 * Hook for gsap based slideshow
 *
 * @usage useSlideshow(ref, { selector: `.${styles.item}`, duration: 1 })
 */

const defaultOptions = {
  duration: 5,
  delay: 4,
  selector: 'img',
  isAutoPlaying: true,
  scale: 1.1,
}

export const useSlideshow = (ref, options) => {
  // options
  const mergedOptions = { ...defaultOptions, ...options }
  const { duration, delay, selector, isAutoPlaying, scale } = mergedOptions

  // state management
  const [items, setItems] = useState([])
  const index = useRef(0)
  const isAnimating = useRef(false)
  const autoTimer = useRef()

  const paginate = useCallback(
    (direction = 1) => {
      if (!isAnimating.current) {
        // if (isAutoPlaying) autoplay()
        if (isAutoPlaying) {
          if (autoTimer.current) {
            clearTimeout(autoTimer.current)
          }
          autoTimer.current = setTimeout(() => paginate(1), delay * 1000)
        }

        const previousIndex = index.current
        const targetIndex = wrap(0, items.length, index.current + direction)

        // store isAnimating state in ref
        isAnimating.current = true

        const tl = gsap.timeline({
          onComplete: () => {
            // update refs
            index.current = targetIndex
            isAnimating.current = false
          },
        })

        // make sure current item is on top
        tl.set(items[targetIndex], { zIndex: 1 })
        tl.to(items[targetIndex], {
          duration: duration,
          autoAlpha: 1,
          scale: 1,
        })

        // after the new image animates in, we can hide the old one that's behind it (aids browser performance)
        tl.set(items[previousIndex], {
          autoAlpha: 0,
          scale: scale,
          zIndex: 0,
        })

        // reset z-index
        tl.set(items[targetIndex], { zIndex: 0 })
      } else {
        // console.warn('ignored, currently animating')
      }
    },
    [duration, isAutoPlaying, items, scale, delay]
  )

  // handle events
  const handleClickNext = () => paginate(1)
  const handleClickPrev = () => paginate(-1)

  const autoplay = useCallback(() => {
    if (autoTimer.current) {
      clearTimeout(autoTimer.current)
    }
    autoTimer.current = setTimeout(() => paginate(1), delay * 1000)
  }, [delay, paginate])

  useEffect(() => {
    if (isAutoPlaying) autoplay()
  }, [items, autoplay, isAutoPlaying])

  // setup
  useEffect(() => {
    // query items
    const itemsArray = gsap.utils.toArray(
      ref.current.querySelectorAll(selector)
    )

    // store item selector in state
    setItems(itemsArray)

    // set initial animation state
    gsap.set(itemsArray, { autoAlpha: 0, scale: scale })

    // animate the first image in (don't put this in the timeline because it's different - there's no image showing behind it)
    gsap.to(itemsArray[0], { duration: duration, autoAlpha: 1, scale: 1 })
  }, [ref, selector, duration, scale])

  return { handleClickNext, handleClickPrev }
}
