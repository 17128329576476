import { getFluidImage } from '@/storyblok/utils/getFluidImage'
import { getFixedImage } from '@/storyblok/utils/getFixedImage'

/**
 * get post media
 */

export const getMedia = (media, config = { maxWidth: 1000 }) => {
  if (media?.component === 'image') {
    return {
      mediaType: 'image',
      ...getFluidImage(media.image?.filename, {
        ...config,
      }),
    }
  }

  if (media?.component === 'video_loop') {
    return {
      mediaType: 'video_loop',
      video: {
        src: media.asset?.filename,
        width: parseInt(media.width) || 500,
        height: parseInt(media.height) || 400,
      },
    }
  }

  if (media?.component === 'vimeo_loop') {
    return {
      mediaType: 'vimeo_loop',
      video: {
        src: media.video_url,
        width: parseInt(media.width) || 500,
        height: parseInt(media.height) || 400,
      },
    }
  }

  if (media.component === 'video_player') {
    const width = parseInt(media.width) || 500
    const height = parseInt(media.height) || 400

    return {
      mediaType: 'video_player',
      video: {
        src: media.video?.filename,
        aspectRatio: height / width,
        poster: media.poster
          ? {
              image: getFixedImage(media.poster?.filename, {
                width: 600,
              }),
            }
          : null,
      },
    }
  }

  console.warn(`getMedia: format not supported: ${media?.component}`)
  return null
}
