import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { browserState, menuState, introState } from '@/store'
import { Media } from '@/components/Media'
import { useSlideshow } from '@/hooks/useSlideshow'
import { useTransitionIn } from './FullScreenGallery.gsap'
import * as styles from './FullScreenGallery.styles.scss'

const FullScreenGallery = ({ slides, heading, description }) => {
  const { device } = useRecoilValue(browserState)
  const introComplete = useRecoilValue(introState)
  const setIsMenuOpen = useSetRecoilState(menuState)
  const handleClickInfo = () => setIsMenuOpen(true)
  const rootRef = useRef()
  const slideshowRef = useRef()

  const { handleClickNext, handleClickPrev } = useSlideshow(slideshowRef, {
    selector: `.${styles.mediaItem}`,
    duration: 0.5,
    delay: 3,
    scale: 1,
    isAutoPlaying: introComplete,
  })

  useTransitionIn(rootRef, {
    selectors: { footer: `.${styles.footer}` },
    isReady: introComplete,
  })

  return (
    <div ref={rootRef} className={styles.FullScreenGallery}>
      <div className={styles.media} ref={slideshowRef}>
        {slides.length > 1 && (
          <div>
            <button
              type="button"
              className={styles.btnPrev}
              aria-label="Previous Image"
              onClick={handleClickPrev}
              data-cursor="prev"
            />
            <button
              type="button"
              className={styles.btnNext}
              aria-label="Next Image"
              onClick={handleClickNext}
              data-cursor="next"
            />
          </div>
        )}
        <div className={styles.slides}>
          {slides.map((item) => {
            return (
              <div key={item.id} className={styles.mediaItem}>
                {device === 'mobile' && item.mediaMobile ? (
                  <Media {...item.mediaMobile} isFull objectFit="cover" />
                ) : (
                  <Media {...item.media} isFull objectFit="cover" />
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.footer}>
        {heading && <h2>{heading}</h2>}
        {description && <span>{description}</span>} /{' '}
        <button
          type="button"
          onClick={handleClickInfo}
          className={styles.btnInfo}
        >
          more info
        </button>
      </div>
    </div>
  )
}

FullScreenGallery.defaultProps = {
  slides: [],
}

FullScreenGallery.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
}

export { FullScreenGallery }
