import React from 'react'
import { graphql } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'

import Page from '@/storyblok/templates/Page.storyblok'

/**
 * Render storyblok pages
 */

const PageBlueprint = ({ data, location, pageContext }) => {
  const story = useStoryblok(data.story, location)

  return <Page story={story} pageContext={pageContext} />
}

export default PageBlueprint

export const query = graphql`
  query StoryblokEntryByID($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      name
      content
      slug
      uuid
      full_slug
      field_component
    }
  }
`
